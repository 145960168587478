import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import logoTwo from "../assets/images/lcs-logo-2.svg";
import aboutImg from "../assets/images/luke.jpeg";
import topRightImg from "../assets/images/corner-img.png";
import { SET_CURRENT_STEP, SET_USER_DETAIL } from "../store/actions";
// import { Button, Modal } from "bootstrap";
import "@popperjs/core";

export const WelcomeTwo = () => {
  const dispatch = useDispatch();

  const categoryLength = useSelector((state) => state.categoryLength);

  var req = new XMLHttpRequest();
  req.open("GET", document.location, false);
  req.send(null);
  var headers = req.getAllResponseHeaders().toLowerCase();
  headers = headers.split(/\n|\r|\r\n/g).reduce(function (a, b) {
    if (b.length) {
      var [key, value] = b.split(": ");
      a[key] = value;
    }
    return a;
  }, {});


  if (headers) {

    const employeeData = {
      name: headers?.Name,
      email: headers?.Email,
      age: headers?.Age,
      gender: headers?.Gender,
      corporate_id: headers?.corporate_id,
    };
    dispatch({ type: SET_USER_DETAIL, value: employeeData });
    console.log("employeeData", employeeData);
  }

  const currentStep = useSelector((state) => state.currentStep);
  const [show, setShow] = useState(false);

  if (currentStep === 1) {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      window.history.go(1);
    };
  }

  return (
    <>
      <div className="welcome-main">
        <img src={topRightImg} alt="lc survey" className="topright-img" />
        <div className="mt-4 mt-md-5 welcome-logo">
          <img src={logoTwo} alt="lc survey" />
        </div>
        <div className="mt-4 mt-md-5 container-fluid welcome-mid-col">
          <div className="row">
            <div className="col-md-8">
              <h1 className="page-title mt-2 mt-md-5 pt-4 pt-md-5">
                The You Care Lifestyle Score By Luke Coutinho is back. This time
                it is bigger and better.
              </h1>
              <p>
                More than 12,000 plus people took level 1 of the one-of-its-kind
                score and embarked on their path to reach their lifestyle goals.
                It is now time to delve deeper. Are you taking this score for
                the first time? Here is a quick recap.
              </p>
              <h2 className="wl-sub-text">
                What is the You Care Lifestyle Score? What sets it apart?
              </h2>

              <p className="mb-0 mt-3 mt-md-3">
                We are living in times where setting up a medical test, whether
                a complete blood count, HbA1c, lipid profiles, TSH, or CRP,
                among others - can be done at the mere click of a few buttons.
                Yet, Luke Coutinho and his integrative team of experts
                identified that there was no single test that could help you
                assess your lifestyle. To bridge this gap, we spent months
                creating the You Care Lifestyle Score.
              </p>
              <p className="mb-0 mt-3 mt-md-3">
                Simple and non-invasive, it needs no needles or swabs. All it
                requires is an investment of a few minutes to reflect on your
                current lifestyle and carefully answer multiple-choice questions
                to get a score. Every question has a unique takeaway for you at
                the end.
              </p>
              <h2 className="wl-sub-text mb-0 mt-3 mt-md-3">
                How is the You Care Lifestyle Score 2.0 different?
              </h2>
              <p className="mb-0 mt-3 mt-md-3">
                The YCL score evaluates you based on the fundamental lifestyle
                pillars for good health, prevention, and recovery.
              </p>
              <p className="mb-0 mt-3 mt-md-3">
                Apart from deep cellular nutrition, activity, sleep, and
                emotional wellness, level 2.0 also introduces two new lifestyle
                pillars - sexual wellness (optional) and general wellness. Here
                we have covered questions on intimacy, bonding, sexual drive,
                satisfaction, the quality of your hair, nails, environment,
                regularity of menstrual cycles (if applicable), and more.
              </p>
              <p className="mb-0 mt-3 mt-md-3">
                Every question will give you a deeper insight into your overall
                health. From our years of experience in consulting patients
                across the globe, we have learned that it is not just the inner
                but the outer environment that matters, too. You could be eating
                the best food in the world but still feel disconnected from your
                partner or live in a polluted environment. Our You Care
                Lifestyle Score 2.0 brings out less-talked about and deep
                questions to give you a 360° overview of your health. Don’t
                worry if your score is not perfect. It means you still have the
                scope to hit your goals. We hope this score can become a great
                starting point to begin your journey to holistic wellness.
              </p>

              {/* <p className="mb-0 mt-3 mt-md-4">Let’s find out!</p> */}
              <div className="bottom-row d-xl-flex flex-xl-row align-items-center mt-0 mt-xl-4 pt-4">
                <button
                  className="btn btn-primary btn-2"
                  onClick={() =>
                    dispatch({ type: SET_CURRENT_STEP, value: currentStep + 1 })
                  }
                >
                  Click here to get your You Care Lifestyle Score
                </button>
                <div className="ms-0 ms-xl-4 d-flex justify-content-center flex-column mt-4 mt-xl-0">
                  <div className="text-r">
                    Remember, <span onClick={() =>
                      setShow(show === true ? false : true)
                    } >You Care</span> is all about
                    <span> YOU.</span>
                  </div>
                  <p className="mt-1 mb-0">
                    We are here to help you build a better, healthier, and
                    robust YOU.
                  </p>
                </div>
              </div>
              <div className="disclaimer d-none d-md-block mt-2 mt-xl-5 pt-4">
                Disclaimer: Please note the information in the given survey is
                not intended to diagnose, prescribe, or replace the advice of a
                doctor or other healthcare professionals. Make an informed
                decision.
              </div>
            </div>
            <div className="col-md-4 mt-2 mt-md-5 pt-4 pt-md-5">
              {/* right */}
              <h3 className="about-title">About Luke Coutinho</h3>
              <img src={aboutImg} alt="lc survey" className="about-img" />

              <p className="mb-0 mt-3 mt-md-4">
                Luke Coutinho is a globally renowned holistic lifestyle coach in
                Integrative and Lifestyle Medicine and a pioneering figure of
                preventive care in India. In a world where accessible healthcare
                is a dream for many, his free videos, blogs, challenges, ebooks,
                and budget programs have helped thousands successfully reverse
                lifestyle disorders. This author of four bestselling books on
                wellness founded You Care - All About You, a unique community
                concept with millions of followers connected by the deep intent
                to rebuild themselves in all aspects of health and lifestyle. He
                also co-founded You Care Lifestyle, a unique e-commerce platform
                that brings together chemical-free, ethical, and certified
                brands and lifestyle products under one roof. Luke's approach
                towards prevention and disease management revolves around four
                pillars of lifestyle namely: Balanced and deep cellular
                nutrition, Adequate Exercise, Quality Sleep, and Emotional
                Detox. He designed the You Care Quality of Lifestyle Survey to
                help you relook at the way you are living, assess each of these
                four pillars of lifestyle, and inspire you to become a
                healthier, happier, and robust version of YOU. Take this survey
                and share it with your loved ones, too.
              </p>
            </div>
            <div className="col">
              <div className="disclaimer d-md-none mt-3 pt-3">
                Disclaimer: Please note the information in the given survey is
                not intended to diagnose, prescribe, or replace the advice of a
                doctor or other healthcare professionals. Make an informed
                decision.
              </div>
            </div>
            {
              show &&
              <div className="text-r">
                <span>{JSON.stringify(headers)}</span>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};
