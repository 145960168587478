import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { SET_CURRENT_STEP, SET_USER_FORM_DATA } from "../store/actions";
import { onlyInteger } from "../config/utils";
import countryData from "../config/countries.json";
import { useSelector } from "react-redux";
import { Skip } from "./Skip";

export const UserForm = () => {
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState("");
  // const [selectedCity, setSelectedCity] = useState("");
  const userDetail = useSelector((state) => state.userDetail);
  const isGutPrePostAssessment = useSelector(
    (state) => state.isGutPrePostAssessment
  );
  const [surveySlug, setSurveySlug] = useState(window?.location?.pathname);
  const dietOptions = [
    { value: "veg", label: "Vegetarian" },
    { value: "non-veg", label: "Non-vegetarian" },
    { value: "jain", label: "Jain" },
    { value: "paleo", label: "Paleo" },
    { value: "keto", label: "Keto" },
    { value: "pegan", label: "Pegan" },
    { value: "vegan", label: "Vegan" },
    { value: "other", label: "Other" },
  ];
  const heightOptions = [
    { value: "feet", label: "Feet" },
    { value: "cms", label: "Centimeter" },
  ];
  const weightOptions = [
    { value: "kg", label: "kilogram" },
    { value: "lbs", label: "Pound" },
  ];
  const countryName = Object.keys(countryData);
  const countryOptions = [];
  countryName.map((ele) => {
    return countryOptions.push({ value: ele, label: ele, ...ele });
  });

  const availableCity =
    countryData[selectedCountry]?.map((ele) => {
      return { value: ele, label: ele, ...ele };
    }) || [];

  return (
    <Formik
      initialValues={userDetail}
      enableReinitialize={true}
      onSubmit={async (values) => {
        dispatch({ type: SET_USER_FORM_DATA, value: values });
        dispatch({ type: SET_CURRENT_STEP, value: 4 });
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Enter name"),
        email: Yup.string().required("Enter email"),
        age: Yup.string().required("Enter age"),
        gender: Yup.string().required("Select gender"),
        city: isGutPrePostAssessment
          ? Yup.string()
          : Yup.string().required("Select city"),
        country: isGutPrePostAssessment
          ? Yup.string()
          : Yup.string().required("Select country"),
        dietPreference: isGutPrePostAssessment
          ? Yup.string()
          : Yup.string().required("Select diet preference"),
        heightUnit: Yup.string().required("Select height unit"),
        height: Yup.number().required("Enter height"),
        weightUnit: Yup.string().required("Select weight unit"),
        weight: Yup.number().required("Enter weight"),
        // isDigestiveHealthCondition: isGutPrePostAssessment ? Yup.string().required("Select digestive health condition") : Yup.string(),
        // digestiveHealthCondition: isGutPrePostAssessment ? Yup.string().when("isDigestiveHealthCondition", {
        //   is: "Yes",
        //   then: Yup.string().required("Enter digestive health condition"),
        //   otherwise: Yup.string(),
        // }) : Yup.string(),

        digestiveHealthCondition: Yup.string().when(
          "isDigestiveHealthCondition",
          {
            is: "Yes",
            then: Yup.string().required("Enter digestive health condition"),
            otherwise: Yup.string(),
          }
        ),
      })}
    >
      {(props) => {
        const {
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleSubmit,
        } = props;
        return (
          <form onSubmit={handleSubmit}>
            <div className="lc-survey-form">
              <div className="d-flex justify-content-between">
                <div>
                  <h1 className="page-title">
                    Let's get <span>started</span>
                  </h1>
                  {surveySlug === "/gut-pre-assessment" ? (
                    <>
                      <p>
                        In any journey to reach B, you need to figure out where
                        point A is. To understand what your goals for your gut
                        are, you need to know the current situation of it.
                        Here’s our specially designed pre-assessment that will
                        help you get a sneak peek into it.
                      </p>
                      <p className="sub-text">TAKE THE PRE-ASSESSMENT HERE</p>
                    </>
                  ) : surveySlug === "/gut-post-assessment" ? (
                    <>
                      <p>
                        We hope this 21-day Gut Reset has provided you with
                        valuable insights into your unique gut health and
                        empowered you to take charge of it. Now that you've
                        experienced the first taste of what a balanced gut feels
                        like, assess your progress by taking this
                        post-assessment quiz. This will give you the opportunity
                        to reflect on your learnings and get a gut score to tell
                        you how far you have come.
                      </p>
                      <p className="sub-text">
                        TAKE THE 21-DAY GUT RESET POST-ASSESSMENT
                      </p>
                    </>
                  ) : (
                    <p className="sub-text">
                      Before we begin, please fill in your details to proceed.
                    </p>
                  )}
                </div>
              </div>
              <div className="lcs-form mt-1 mt-lg-2 mt-xl-3">
                <div className="mb-3 mb-xxl-4">
                  <label htmlFor="yourname" className="form-label">
                    Your name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      touched.name && errors.name ? "is-invalid" : ""
                    }`}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  {touched.name && errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
                <div className="mb-3 mb-xxl-4">
                  <label htmlFor="youremail" className="form-label">
                    Your email<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      touched.email && errors.email ? "is-invalid" : ""
                    }`}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {touched.email && errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
                <div className="mb-3 mb-xl-4">
                  <label htmlFor="yourage" className="form-label">
                    Your age<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      touched.age && errors.age ? "is-invalid" : ""
                    }`}
                    name="age"
                    value={values.age}
                    onChange={handleChange}
                    onKeyDown={onlyInteger}
                    maxLength={3}
                  />
                  {touched.age && errors.age && (
                    <div className="invalid-feedback">{errors.age}</div>
                  )}
                </div>
                <label className="form-label d-block">
                  Select your gender<span style={{ color: "red" }}>*</span>
                </label>
                <div className="d-flex inline mt-md-3 mb-3">
                  <div className="form-check me-4 me-md-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="male"
                      checked={values.gender === "M"}
                      onChange={() => setFieldValue("gender", "M")}
                    />
                    <label className="form-check-label" htmlFor="male">
                      Male
                    </label>
                  </div>
                  <div className="form-check me-4 me-md-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="female"
                      checked={values.gender === "F"}
                      onChange={() => setFieldValue("gender", "F")}
                    />
                    <label className="form-check-label" htmlFor="female">
                      Female
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="other"
                      checked={values.gender === "O"}
                      onChange={() => setFieldValue("gender", "O")}
                    />
                    <label className="form-check-label" htmlFor="other">
                      Other
                    </label>
                  </div>
                </div>

                {!isGutPrePostAssessment && (
                  <>
                    <div className="mb-3 mb-xxl-4 ls-select">
                      <label className="pb-2">
                        Your country<span style={{ color: "red" }}>*</span>
                      </label>

                      <Select
                        placeholder="Select your country"
                        className={`${
                          touched.country && errors.country ? "is-invalid" : ""
                        }`}
                        options={countryOptions}
                        onChange={(e) => {
                          setFieldValue("country", e?.value);
                          setSelectedCountry(e?.value);
                        }}
                      />
                      {touched.country && errors.country && (
                        <div className="invalid-feedback">{errors.country}</div>
                      )}
                    </div>

                    <div className="mb-3 mb-xxl-4 ls-select">
                      <label className="pb-2">
                        Your city<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select your city"
                        className={`${
                          touched.city && errors.city ? "is-invalid" : ""
                        }`}
                        options={availableCity}
                        onChange={(e) => setFieldValue("city", e?.value)}
                        // onChange={(e) => setSelectedCountry(e.value)}
                      />
                      {touched.city && errors.city && (
                        <div className="invalid-feedback">{errors.city}</div>
                      )}
                    </div>

                    <div className="mb-3 mb-xxl-4 ls-select">
                      <label className="pb-2">
                        Diet preferences<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        placeholder="Select your diet preference"
                        className={`${
                          touched.dietPreference && errors.dietPreference
                            ? "is-invalid"
                            : ""
                        }`}
                        options={dietOptions}
                        onChange={(e) =>
                          setFieldValue("dietPreference", e?.value)
                        }
                      />
                      {touched.dietPreference && errors.dietPreference && (
                        <div className="invalid-feedback">
                          {errors.dietPreference}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {values.dietPreference === "other" ? (
                  <div className="mb-3 mb-xxl-4">
                    <label htmlFor="details" className="form-label">
                      Add details here<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        touched.preferenceDetails && errors.preferenceDetails
                          ? "is-invalid"
                          : ""
                      }`}
                      name="preferenceDetails"
                      value={values.preferenceDetails}
                      onChange={handleChange}
                    />
                    {touched.preferenceDetails && errors.preferenceDetails && (
                      <div className="invalid-feedback">
                        {errors.preferenceDetails}
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {!isGutPrePostAssessment && (
                <>
                  <div className="mb-3 mb-xl-4">
                    <label htmlFor="ethnicity" className="form-label">
                      Ethnicity<span> (optional)</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        touched.ethnicity && errors.ethnicity
                          ? "is-invalid"
                          : ""
                      }`}
                      name="ethnicity"
                      value={values.ethnicity}
                      onChange={handleChange}
                      // onKeyDown={onlyInteger}
                      // maxLength={3}
                    />
                  </div>
                </>
              )}
              <div className="mb-3 mb-xxl-4 ls-select">
                <label className="pb-2">
                  Height unit<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  placeholder="Select your height unit"
                  className={`${
                    touched.heightUnit && errors.heightUnit ? "is-invalid" : ""
                  }`}
                  options={heightOptions}
                  onChange={(e) => setFieldValue("heightUnit", e?.value)}
                />
                {touched.heightUnit && errors.heightUnit && (
                  <div className="invalid-feedback">{errors.heightUnit}</div>
                )}
              </div>

              {values.heightUnit ? (
                <div className="mb-3 mb-xl-4">
                  <label htmlFor="height" className="form-label">
                    Your height
                    <span style={{ color: "red " }}>*</span>
                  </label>
                  <input
                    type="number"
                    className={`form-control ${
                      touched.height && errors.height ? "is-invalid" : ""
                    }`}
                    name="height"
                    value={values.height}
                    onChange={handleChange}
                    onKeyDown={onlyInteger}
                    aria-label="Dollar amount (with dot and two decimal places)"
                    // maxLength={3}
                  />
                  {touched.height && errors.height && (
                    <div className="invalid-feedback">{errors.height}</div>
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="mb-3 mb-xxl-4 ls-select">
                <label className="pb-2">
                  Weight unit<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  placeholder="Select your weight unit"
                  className={`${
                    touched.weightUnit && errors.weightUnit ? "is-invalid" : ""
                  }`}
                  options={weightOptions}
                  onChange={(e) => setFieldValue("weightUnit", e?.value)}
                />
                {touched.weightUnit && errors.weightUnit && (
                  <div className="invalid-feedback">{errors.weightUnit}</div>
                )}
              </div>
              {values.weightUnit ? (
                <div className="mb-3 mb-xl-4">
                  <label htmlFor="weight" className="form-label">
                    Your weight<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    className={`form-control ${
                      touched.weight && errors.weight ? "is-invalid" : ""
                    }`}
                    name="weight"
                    value={values.weight}
                    onChange={handleChange}
                    onKeyDown={onlyInteger}
                    //  maxLength={4}
                  />
                  {touched.weight && errors.weight && (
                    <div className="invalid-feedback">{errors.weight}</div>
                  )}
                </div>
              ) : (
                ""
              )}

              <label className="form-label d-block">
                Any known digestive health conditions
                <span style={{ color: "red" }}>*</span>
              </label>
              <div className="d-flex inline mt-md-3 mb-3">
                <div className="form-check me-4 me-md-5">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isDigestiveHealthCondition"
                    id="Yes"
                    checked={values.isDigestiveHealthCondition === "Yes"}
                    onChange={() =>
                      setFieldValue("isDigestiveHealthCondition", "Yes")
                    }
                  />
                  <label className="form-check-label" htmlFor="Yes">
                    Yes
                  </label>
                </div>
                <div className="form-check me-4 me-md-5">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isDigestiveHealthCondition"
                    id="No"
                    checked={
                      values.isDigestiveHealthCondition === "No" ||
                      values.isDigestiveHealthCondition === undefined
                    }
                    onChange={() =>
                      setFieldValue("isDigestiveHealthCondition", "No")
                    }
                  />
                  <label className="form-check-label" htmlFor="No">
                    No
                  </label>
                </div>
              </div>

              {values.isDigestiveHealthCondition === "Yes" ? (
                <div className="mb-3 mb-xl-4">
                  <label
                    htmlFor="digestiveHealthCondition"
                    className="form-label"
                  >
                    Write your digestive health condition
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.digestiveHealthCondition ? "is-invalid" : ""
                    }`}
                    name="digestiveHealthCondition"
                    value={values.digestiveHealthCondition}
                    onChange={handleChange}
                    // onKeyDown={onlyInteger}
                    //  maxLength={4}
                  />

                  {errors.digestiveHealthCondition && (
                    <div className="invalid-feedback">
                      {errors.digestiveHealthCondition}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              {/* <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={values.isSwitch}
                  onChange={() => setFieldValue("isSwitch", !values.isSwitch)}
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                  Are you a doctor?
                </label>
              </div> */}
            </div>
            <div className="btn-row d-flex justify-content-end mt-2 mt-xl-3 pt-1">
              <button type="submit" className="btn btn-primary btn-2">
                Proceed
              </button>
            </div>
            <div className="disclaimer mt-0 mt-md-2 pt-4">
              Disclaimer: All the data collected as part of this survey is
              confidential and stored in a password-protected electronic format.
              The results of this study will be used for research purposes only.
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
