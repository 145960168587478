import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import logoTwo from "../assets/images/lcs-logo-2.svg";
import aboutImg from "../assets/images/luke.jpeg";
import topRightImg from "../assets/images/corner-img.png";
import { SET_CURRENT_STEP, SET_USER_DETAIL } from "../store/actions";
// import { Button, Modal } from "bootstrap";
import "@popperjs/core";

export const WelcomeOne = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const categoryLength = useSelector((state) => state.categoryLength);

  // const heads = window.location.href;

  // const headers = useParams()

  // var req = new XMLHttpRequest();
  // req.open("GET", document.location, false);
  // req.send(null);
  // var headers = req.getAllResponseHeaders().toLowerCase();

  var req = new XMLHttpRequest();
  req.open("GET", document.location, false);
  req.send(null);
  var headers = req.getAllResponseHeaders().toLowerCase();
  headers = headers.split(/\n|\r|\r\n/g).reduce(function (a, b) {
    if (b.length) {
      var [key, value] = b.split(": ");
      a[key] = value;
    }
    return a;
  }, {});

  // const header = {
  //   Name: "Reezwon Boi",
  //   Email: "Reezwon.Boi@gmail.com",
  //   Age: "35",
  //   Gender: "M",
  //   corporate_id: "01",
  // };
  // console.log("headers", headers);
  // if (headers) {

  //   const employeeData = {
  //     name: headers?.Name,
  //     email: headers?.Email,
  //     age: headers?.Age,
  //     gender: headers?.Gender,
  //     corporate_id: headers?.corporate_id,
  //   };

  //   dispatch({ type: SET_USER_DETAIL, value: employeeData });
  //   console.log("employeeData", employeeData);
  // }



  const query = new URLSearchParams(window.location.search);
  var employeeData = {}
  if (query.get("corporate_id")) {
    employeeData = {
      name: query.get("Name"),
      email: query.get("Email"),
      age: query.get("Age"),
      gender: query.get("Gender"),
      corporate_id: query.get("corporate_id"),
    };
    
    dispatch({ type: SET_USER_DETAIL, value: employeeData });
  }



  // const data = JSON.stringify(headers);

  // const element = document.createElement("a");
  // const file = new Blob([data], { type: "text/plain" });
  // element.href = URL.createObjectURL(file);
  // element.download = "myFile.txt";
  // document.body.appendChild(element); // Required for this to work in FireFox
  // element.click();

  const currentStep = useSelector((state) => state.currentStep);


  if (currentStep === 1) {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      console.log("event", event.target.location.href);
      window.history.go(1);
    };
  }

  return (
    <>
      <div className="welcome-main">
        <img src={topRightImg} alt="lc survey" className="topright-img" />
        <div className="mt-4 mt-md-5 welcome-logo">
          <img src={logoTwo} alt="lc survey" />
        </div>
        <div className="mt-4 mt-md-5 container-fluid welcome-mid-col">
          <div className="row">
            <div className="col-md-8">
              <h1 className="page-title mt-2 mt-md-5 pt-4 pt-md-5">
                Hello, welcome to The You Care Quality of Lifestyle Survey!
              </h1>
              <h2 className="wl-sub-text">
                Adding life to your years or years to your life. Which one would
                you choose?
              </h2>

              <p className="mb-0 mt-3 mt-md-5">
                Have you ever taken the time out to assess and relook at the way
                you are living? How does the quality and quantity of the food
                you eat, its sources, absorption, and assimilation affect your
                health? How does the level of your activity play an intrinsic
                role in boosting your immune system? How could the quality of
                your sleep help to keep disease at bay? Or how does your ability
                to forgive, accept, and let go, avoid any existing condition you
                may have from deteriorating further?
              </p>
              <p className="mb-0 mt-3 mt-md-4">
                Take this survey to unveil the quality of your lifestyle.
              </p>
              <p className="mb-0 mt-3 mt-md-4">Let’s find out!</p>
              <div className="bottom-row d-xl-flex flex-xl-row align-items-center mt-0 mt-xl-4 pt-4">
                <button
                  className="btn btn-primary btn-2"
                  onClick={() =>
                    dispatch({ type: SET_CURRENT_STEP, value: currentStep + 1 })
                  }
                >
                  Take the survey
                </button>

                <div className="ms-0 ms-xl-4 d-flex justify-content-center flex-column mt-4 mt-xl-0">
                  <div className="text-r">
                    Remember, <span onClick={() =>
                      setShow(show === true ? false : true)
                    } >You Care</span> is all about
                    <span> YOU.</span>
                  </div>
                  <p className="mt-1 mb-0">
                    We are here to help you build a better, healthier, and
                    robust YOU.
                  </p>
                </div>
              </div>
              <div className="disclaimer d-none d-md-block mt-2 mt-xl-5 pt-4">
                Disclaimer: Please note the information in the given survey is
                not intended to diagnose, prescribe, or replace the advice of a
                doctor or other healthcare professionals. Make an informed
                decision.
              </div>
            </div>
            <div className="col-md-4 mt-2 mt-md-5 pt-4 pt-md-5">
              {/* right */}
              <h3 className="about-title">About Luke Coutinho</h3>
              <img src={aboutImg} alt="lc survey" className="about-img" />

              <p className="mb-0 mt-3 mt-md-4">
                Luke Coutinho is a globally renowned holistic lifestyle coach in
                Integrative and Lifestyle Medicine and a pioneering figure of
                preventive care in India. In a world where accessible healthcare
                is a dream for many, his free videos, blogs, challenges, ebooks,
                and budget programs have helped thousands successfully reverse
                lifestyle disorders. This author of four bestselling books on
                wellness founded You Care - All About You, a unique community
                concept with millions of followers connected by the deep intent
                to rebuild themselves in all aspects of health and lifestyle. He
                also co-founded You Care Lifestyle, a unique e-commerce platform
                that brings together chemical-free, ethical, and certified
                brands and lifestyle products under one roof. Luke's approach
                towards prevention and disease management revolves around four
                pillars of lifestyle namely: Balanced and deep cellular
                nutrition, Adequate Exercise, Quality Sleep, and Emotional
                Detox. He designed the You Care Quality of Lifestyle Survey to
                help you relook at the way you are living, assess each of these
                four pillars of lifestyle, and inspire you to become a
                healthier, happier, and robust version of YOU. Take this survey
                and share it with your loved ones, too.
              </p>
            </div>
            <div className="col">
              <div className="disclaimer d-md-none mt-3 pt-3">
                Disclaimer: Please note the information in the given survey is
                not intended to diagnose, prescribe, or replace the advice of a
                doctor or other healthcare professionals. Make an informed
                decision.
              </div>

            </div>
            {
              show &&
              <div className="text-r">
                <span>{JSON.stringify(employeeData)}</span>

              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};
