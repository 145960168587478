import React from "react";

import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/images/lcs-logo.svg";
import { SET_CURRENT_STEP } from "../store/actions";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const selectedCategoryIndex = useSelector(
    (state) => state.selectedCategoryIndex
  );
  const categoryList = useSelector((state) => state.sideBarCategoryList);
  const userFormData = useSelector((state) => state.userFormData);
  const isGutPrePostAssessment = useSelector((state) => state.isGutPrePostAssessment);

  return (
    <>
      <div className="sidebar mt-2">
        <div className="lcs-logo text-center">
          <img src={logo} alt="lc survey" />
        </div>
        <div className="sidebar-text-sml pt-3 pt-md-4">
          Hey{" "}
          <span>
            {" "}
            {Object.keys(userFormData).length !== 0
              ? userFormData.name
              : "there"}
            ,
          </span>
        </div>
        {!isGutPrePostAssessment && <div className="sidebar-text-mid pt-2 pt-md-3">
          These are your {categoryList.length === 4 ? "four" : "six"}
          <span>holistic health pillars.</span> 
        </div> 
        }
      
        {/* <div className="sidebar-text-sml mt-0 pt-2 ">
          Click on each to conduct survey.
        </div> */}
        <div className="four-boxes d-flex  mt-4 gap-2">
          {categoryList.map((item, index) => {
            const backgroundColorStyle = {};
            const colorStyle = {};
            if (selectedCategoryIndex === index) {
              backgroundColorStyle.backgroundColor = item.backgroundColor;
              colorStyle.color = "#1B1F22";
            }
            return (
              <div
                key={item.id}
                className="four-box-item d-flex flex-column justify-content-center align-items-center"
                style={backgroundColorStyle}
              >
                <figure className="box-icon">
                  <img
                    src={item.image}
                    height={117}
                    width={117}
                    alt="lc survey"
                  />
                </figure>
                <p className="box-text mb-0 pt-2 " style={colorStyle}>
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
        <div className="share-block d-flex flex-column  ">
          <div className="share-text my-3 ">
            Share this survey with your family and friends.
          </div>
          <div className="share-btn mt-2">
            <button 
              className="btn btn-primary " 
              onClick={() => dispatch({ type: SET_CURRENT_STEP, value: 3 })}
              style={{width:'160px'}}
            >
              Share
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
