import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";

import closeBtn from "../assets/images/close.svg";
import { validateMobileNumber } from "../config/utils";
import { SET_CURRENT_STEP } from "../store/actions";
import axios from "axios";
import { API_URL } from "../config/constant";

export const InviteForm = () => {
  const dispatch = useDispatch();
  const pushButton = useRef(null);
  const currentStep = useSelector((state) => state.currentStep);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ invite: [{ name: "", email: "", mobileNumber: "" }] }}
        onSubmit={(values) => {
          axios.post(`${API_URL}/invite`, { contactDetails: values.invite });
          dispatch({ type: SET_CURRENT_STEP, value: currentStep + 1 });
        }}
        validationSchema={Yup.object().shape({
          invite: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required("Enter name"),
              email: Yup.string()
                .required("Enter email")
                .email("Enter valid email"),
              mobileNumber: Yup.string()
                .required("Enter mobile number")
                .min(10, "Mobile number must contains at least 10 digits"),
            })
          ),
        })}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          // console.log(errors);
          return (
            <Form onSubmit={handleSubmit} className="invite-form">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="invite-form-main">
                  <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div>
                      <h1 className="page-title">
                         <p>Invite <strong>friends and family!</strong></p>
                      </h1>
                      <p className="sub-text">
                        You can add upto 5 recipients maximum
                      </p>
                    </div>
                    <div className="btn-bdr-block  text-md-end ">
                      <button
                        className="btn btn-bdr"
                        type="button"
                        onClick={() => pushButton.current.click()}
                      >
                        + ADD RECIPIENTS
                      </button>
                    </div>
                  </div>
                  <div className="d-flex invite-form-block">
                    <FieldArray
                      name="invite"
                      render={({ remove, push }) => (
                        <>
                          {values.invite.map((item, index) => {
                            return (
                              <div className="invite-form-item" key={index}>
                                {values.invite.length > 1 && (
                                  <div
                                    className="close-btn"
                                    onClick={() => remove()}
                                  >
                                    <img src={closeBtn} alt="lc survey" />
                                  </div>
                                )}
                                <div className="mb-3 mb-md-4">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      touched.invite &&
                                      touched?.invite[index]?.name &&
                                      errors.invite &&
                                      errors?.invite[index]?.name
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter friends name"
                                    value={item.name}
                                    name={`invite[${index}].name`}
                                    onChange={handleChange}
                                  />
                                  {touched.invite &&
                                    touched?.invite[index]?.name &&
                                    errors.invite &&
                                    errors?.invite[index]?.name && (
                                      <div className="invalid-feedback">
                                        {errors.invite[index].name}
                                      </div>
                                    )}
                                </div>
                                <div className="mb-3 mb-md-4">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      touched.invite &&
                                      touched?.invite[index]?.email &&
                                      errors.invite &&
                                      errors?.invite[index]?.email
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter friends email"
                                    value={item.email}
                                    name={`invite[${index}].email`}
                                    onChange={handleChange}
                                  />
                                  {touched.invite &&
                                    touched?.invite[index]?.email &&
                                    errors.invite &&
                                    errors?.invite[index]?.email && (
                                      <div className="invalid-feedback">
                                        {errors.invite[index].email}
                                      </div>
                                    )}
                                </div>
                                <div className="mb-3 mb-md-4">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      touched.invite &&
                                      touched?.invite[index]?.mobileNumber &&
                                      errors.invite &&
                                      errors?.invite[index]?.mobileNumber
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    maxLength={10}
                                    placeholder="Enter friends mobile number"
                                    value={item.mobileNumber}
                                    name={`invite[${index}].mobileNumber`}
                                    onChange={handleChange}
                                    onKeyDown={validateMobileNumber}
                                  />
                                  {touched.invite &&
                                    touched?.invite[index]?.mobileNumber &&
                                    errors.invite &&
                                    errors?.invite[index]?.mobileNumber && (
                                      <div className="invalid-feedback">
                                        {errors.invite[index].mobileNumber}
                                      </div>
                                    )}
                                </div>
                              </div>
                            );
                          })}
                          <span
                            type="button"
                            style={{ display: "none" }}
                            ref={pushButton}
                            onClick={() =>
                              push({ name: "", email: "", mobileNumber: "" })
                            }
                          ></span>
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="btn-row sharestartbtn d-flex  justify-content-between mt-2 mt-md-3 pt-1">
                  <button
                    className="btn btn-secondary "
                    type="button"
                    onClick={() =>
                      dispatch({ type: SET_CURRENT_STEP, value: 2 })
                    }
                  >
                    Start Survery
                  </button>
                  <button className="btn btn-primary btn-2 sharebtn mt-3 mt-md-0" type="submit">
                    Share
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
