import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { Sidebar } from "./pages/Sidebar";
import { Questions } from "./pages/Questions";
import { InviteForm } from "./pages/InviteForm";
import { Congratulations } from "./pages/Congratulations";
import { ScoreDetails } from "./pages/ScoreDetails";
import { UserForm } from "./pages/UserForm";
import { Download } from "./pages/Download";
import { Welcome } from "./pages/Welcome";
import { API_URL } from "./config/constant";
import {
  SET_CATEGORY,
  SET_OVERALL_CATEGORY,
  SET_QUESTIONS,
  SET_CATEGORY_LENGTH,
  SET_SIDEBAR_CATEGORY,
  SET_CURRENT_STEP,
  SET_IS_GUT_PRE_POST_ASSESSMENT,
} from "./store/actions";
import Loader from "./pages/Loader";
import logoTwo from "./assets/images/lcs-logo-2.svg";

function App() {
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.selectedCategory);
  const [showLoader, setShowLoader] = useState(true);
  const [showError, setShowError] = useState(false);
  const currentStep = useSelector((state) => state.currentStep);
 
  const [currentComponent, setCurrentComponent] = useState(null);

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line

    if( window.location.pathname === '/gut-pre-assessment' || window.location.pathname === '/gut-post-assessment' ){
      dispatch({ type: SET_CURRENT_STEP, value: 2 })
      dispatch({ type: SET_IS_GUT_PRE_POST_ASSESSMENT, value: true })
    } else {
      dispatch({ type: SET_IS_GUT_PRE_POST_ASSESSMENT, value: false })
    }

  }, []);


  const getQuestions = async () => {
    try {

      const url =
        window.location.pathname !== "/"
          ? `${API_URL}/questions${window.location.pathname}`
          : `${API_URL}/questions/null`;
      const { data } = await axios.get(url);
      const categories = [];
      const questions = [];
      const { overAllCategory, questionsData } = data;
      questionsData.forEach(({ categoryId, CategoryQuestions }) => {
        if (CategoryQuestions.length > 0) {
          categories.push(categoryId);
          questions.push(
            ...CategoryQuestions.map((question) => {
              const score = question.options.map((o) => o.score);
              const maxScore = Math.max(...score);
              const minScore = Math.min(...score);
              question.minScore = minScore;
              question.maxScore = maxScore;
              return question;
            })
          );
        }
      });
      dispatch({ type: SET_CATEGORY, value: categories });
      dispatch({ type: SET_SIDEBAR_CATEGORY, value: categories });
      dispatch({ type: SET_QUESTIONS, value: questions });
      dispatch({ type: SET_OVERALL_CATEGORY, value: overAllCategory });
      dispatch({ type: SET_CATEGORY_LENGTH, value: categories.length });
      // dispatch({ type: SET_CURRENT_STEP, value: 7 });
    } catch (e) {
      console.log(e);
      setShowError(true);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setCurrentComponent(<Welcome />);
        break;
      case 2:
        setCurrentComponent(<UserForm />);
        break;
      case 3:
        setCurrentComponent(<InviteForm />);
        break;
      case 4:
        setCurrentComponent(<Questions />);
        break;
      case 5:
        setCurrentComponent(<Congratulations />);
        break;
      case 6:
        setCurrentComponent(<ScoreDetails />);
        break;
      case 7:
        setCurrentComponent(<Download />);
        break;
      default:
        setCurrentComponent(<Welcome />);
        break;
    }
    window.scrollTo(0, 0);
  }, [currentStep]);

  if (showLoader) return <Loader />;

  if (showError)
    return (
      <>
        <div className="welcome-main">
          <div className="mt-4 mt-md-5 welcome-logo text-center">
            <img src={logoTwo} alt="lc survey" />
          </div>
          <h1 className="page-title mt-2 mt-md-5 pt-4 pt-md-5 text-center">
            This Survey URL does not exists
          </h1>
        </div>
      </>
    );

  return (
    <>
      <div className="lc-survey-main">
        {currentStep !== 1 && (
          <div className="left-pane">
            <Sidebar />
          </div>
        )}
        <div
          className={`right-pane ${currentStep === 1 ? "no-pane" : ""}`}
          style={{
            backgroundColor: selectedCategory?.backgroundColor || "#E4F0F1",
          }}
        >
          {currentComponent}
        </div>
      </div>
    </>
  );
}

export default App;
